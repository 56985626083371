

















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    pointId: {
      type: String,
      required: true,
      default: "",
    },
    pointTitle: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      items: [],
      total: 0,
      limit: 0,
    });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`presence/control-point/${props.pointId}/presence`)
        .then(({ data: { presences, total } }) => {
          state.items = presences;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetch);

    const fetchSingleControlPoint = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`control-point/${props.pointId}`)
        .then(({ data: { controlPointRow } }) => {
          state.limit = controlPointRow.limit;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSingleControlPoint);

    return { state };
  },
});
